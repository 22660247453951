/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */


import React, { Component, useDebugValue } from 'react';
import { render } from 'react-dom';
import { AgGridReact } from '@ag-grid-community/react';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { MenuModule } from '@ag-grid-enterprise/menu';
// import Consignmentforceclose from '../m';
import Select from 'react-select';
import { AllModules, GridOptionsWrapper } from '@ag-grid-enterprise/all-modules';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import UpdateButton from "./updatelmstates";
import UpdateTransitButton from "./updatetransitbutton";
import * as Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import ShowTPTGpsMap from "../tptlogin/showtptgpsmap";
import DrawMap from '../common/drawmap';

import $ from 'jquery';
import { getDDMMYYYY, getHyphenYYYYMMDD,getHyphenDDMMMYYYYHHMM,getHyphenDDMMMYYYY,getHyphenYYYYMMDDHHMMSS,getHyphenDDMMYYYYHHMMSS,getDDMMYYYYHHMMSS, getYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenDDMMYYYY } from '../common/utils'; 
// const $ = window.$;
window.jQuery = $;
window.$ = $;
var alertIcon_50 = require("../../assets/icons/accident alert_16.jpeg");
var redirectURL = require('../redirectURL');
var moment = require('moment');
var CELL_DIMENSION_SIZE = 90;
var googleAnalytics = require("../common/googleanalytics");
var axios = require('axios');


export default class SafetyIncidents extends Component {

    constructor(props) {
        super(props);
		this.displayData = [];
        this.state = {
			pageTitle: "Transporter GPS Overspeed",
			eventGridAction: "gridAction",
			eventCounterAction: "counterAction",
			eventFormAction: "formAction",
			showform:'show-m',
			loadshow:'show-n',
			showrefresh:'show-n',
			sliderTranslate:'',
			showdata:this.displayData,
			modules: AllModules,
        	radius:'15',
			rlatitude:'',
			rlongitude:'',
			latitude:'',
			longitude:'',
			defaultradius:0,
			coordinates:[],
			alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
			contentString:"testing",
			mwidth:"col-xl-12 col-lg-12",
			dept_code:'',
			defaultColDef: {
				sortable: true,
				filter: true,
				//editable: true,
				resizable: true
			},
			rowData: [],
			truckList: [],
			originalrowData: [],
			selectedTabLat:'',
			selectedTabLng:'',
			cluster_centroids: [],
			deftab:'all',
			rowModelhighchartoptionsType: 'enterprise',
			rowGroupPanelShow: 'always',
			animateRows: true,
			debug: true,
			showToolPanel: false,
			rowSelection: "single" ,
			context: { componentParent: this },
			frameworkComponents : {
				showtptgpsmap:ShowTPTGpsMap,
                UpdateButton:UpdateButton,
                UpdateTransitButton:UpdateTransitButton,
            },
			loadingCellRenderer: "customLoadingCellRenderer",
			loadingCellRendererParams: { loadingMessage: "One moment please..." },
			rowModelType: "serverSide",
			paginationPageSize:50,
			cacheBlockSize: 50,
			maxBlocksInCache: 2,
		
			showPlant:'show-n',
			showAddr:'show-n',
			request_type:'',
			view_type:'',
			tabplants:[],
			clickabletab:'',
			plantlist:[],
			stateFormData : "",
			sliderStateEmailsTranslate : "",
			overly : "show-n",
            rakeslist : [],
            loadingpoints:[],
            destinationpoints:[],
            userType:"",
            pinnedRegionBottomRowData:[],
            pinnedTransitBottomRowData:[],
            stockData:[],
            totalStock:0,
            totalSales:0,
            totalDispatches:0,
            railShare:0,
            totalFySales:0,
            totalFyDispatches:0,
            railFyShare:0,
            pendingForDispatchStock:0,
            transitStock:0,
            unloadingUpdatedDate:"",
            intransitUpdatedDate:"",
            screenView: "",
            todayInstalled: [],
            totalInstalled: [],
            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
			},
			sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
			},
			filteredData: [],
			truckNoList:[],
			showTrucksList : "show-n",
			startDate:'',
			endDate:'',
			truckNos:[],
			mapinfo:[],
            // duration:"",
			sliderRouteTranslate:'',
			tpttrucks:[],
            truck_no:[],
            tpt_code:[],
            truck_no_option:[],
            tpt_code_option:[],
            lastElement:"",
            lastNumbers:0,
            allData:[],
            page_no:0,
            alrData:0,
            total_count:[],
            // from_date: moment.parseZone().subtract(5,'days').format("YYYY-MM-DD"),
            // to_date: moment.parseZone().format("YYYY-MM-DD"),
            fromDate: moment.parseZone().subtract(7,'days').format("YYYY-MM-DD"),
			toDate: moment.parseZone().format("YYYY-MM-DD"),
		};
		this.onShowTPTGPSMapRoute = this.onShowTPTGPSMapRoute.bind(this);
	}
	/*Alert Popups*/
	closeAlert = () => {
        this.setState({
            show: false
        });
        
        
    }
    onChangeDuration(duration){
		this.setState(
			{ duration },
			// () => console.log(`duration Option selected:`, this.state.duration)
		);
	}
	logPageView = () => {
		try {
			if (googleAnalytics.page.enableGA) {
				googleAnalytics.logPageView();
				let eventGridAction = googleAnalytics.page.actionType.gridAction + " - " + window.location.pathname;
				let eventCounterAction = googleAnalytics.page.actionType.counterAction + " - " + window.location.pathname;
				let eventFormAction = googleAnalytics.page.actionType.formAction + " - " + window.location.pathname;
				this.setState({eventGridAction: eventGridAction, eventCounterAction: eventCounterAction, eventFormAction: eventFormAction});
			} else {
				console.log("GA disabled...");
			}
		} catch(error) {
			console.error("Error occured while logging to GA, error = ", error);
		}
	}
    componentDidMount(){
        this.getApiData();	

    }
    
    getApiData = async () => {
        this.setState({
			loadshow:'show-m',
			overly:'show-m',
        });
        
		var rowData = []		
        loadDateTimeScript();

        // var from_date = moment.parseZone().subtract(7,'days').format("YYYY-MM-DD");
        // var to_date = moment.parseZone().format("YYYY-MM-DD");
        // $("#srfromDate").val(from_date);
		// $("#srtoDate").val(to_date);

        var from_date = this.state.fromDate;
        var to_date = this.state.toDate;

		var params = {
			// transporter_code:transporter_code,
			startdate : from_date,
			enddate : to_date
		}
        try{
			if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
			{
				params.plant_code = localStorage.getItem("pc");
			}
		}catch(e){
			
		}
        
        if(localStorage.getItem("transportercode") !=undefined && localStorage.getItem("transportercode") !="" && localStorage.getItem("transportercode")!="undefined")
        {
            params.transporter_code = localStorage.getItem("transportercode")
        }
        // console.log("params", params)
       await redirectURL.post("/consignments/stoppagereportdata",params)
        .then((response) =>{
            var records =[];
            try{

                if(response.data.report_data !== "" && response.data.report_data !== undefined)
                {
                    records = JSON.parse(response.data.report_data);
                }


            }
            catch(e){

            }
            // console.log("records ", records)
            // var recordsarr = []

            // if(records.length > 0)
            // {
            //     records.map((item) => {
            //         // if(item.trip_start_date !== "" && item.trip_start_date !== undefined)
            //         // {
            //         //     if(item.transit_time !== "" && item.transit_time !== undefined)
            //         //     {
            //         //         var d = item.transit_time;
            //         //         // console.log("dt ",d)
            //         //         var dt = moment.parseZone(item.trip_start_date).add(d,"days").utcOffset("+05:30")._d;
            //         //         //console.log("dt ",dt)
            //         //         item.actual_tat_date = dt;

            //         //         if(item.expected_trip_end !== "" && item.expected_trip_end !== undefined)
            //         //         {
            //         //             var b = item.expected_trip_end;
            //         //         }
            //         //         else{
            //         //             var b = 0;
            //         //         }
            //         //         var dif  = dateDifferenceInHHMM(dt,b)
            //         //         item.tat_diff = dif;
            //         //     }
                        
            //         // }
                    
            //         recordsarr.push(item)
            //     })
            // }
            records = records.filter(e => e.no_of_stoppages !== 0)
            console.log(records,"records stoppages")           
			this.setState({
				rowData:records,
                loadshow:'show-n',
                overly:'show-n',
                // truck_no_option: truckList,
                // tpt_code_option:tptcodes

			})
        })
        // console.log(rowData,"rowData")
    }

	onClickHideAll(){
		this.setState({
			overly : "show-n",
			showTrucksList : "show-n",
			sliderRouteTranslate:'',
		})
	}
	hideList(){
        this.setState({
			loadshow:'show-n',
			overly:'show-n',
			showTrucksList : "show-n",
        });
    }
	
    onGridReady = params => {
		this.gridApi = params.api;
		//console.log("Grid is: ", params);
		this.gridColumnApi = params.columnApi;
    };
    

	onClickFilterSafetyReport = () => {
        this.setState({
			loadshow:'show-m',
			overly:'show-m',
        });
		// var truckNos = this.state.truckNos;
		// var truck_nos = [];
		// if(truckNos.length > 0)
		// {
		// 	truckNos.map(function(e){
		// 		truck_nos.push(e.value)
		// 	});
		// }
		var truckNo = $("#inputTruck").val();
        // var truck_no = [];
        // if(truckNo.length > 0)
		// {
		// 	truckNo.map(function(e){
		// 		truck_no.push(e.value)
		// 	});
		// }
        var tptcode = this.state.tpt_code;
        var tpt_code = [];
        if(tptcode !=null && tptcode != "null")
        {
            if(tptcode.length > 0)
            {
                tptcode.map(function(e){
                    tpt_code.push(e.value)
                });
            }
        }
        
        
		// var from_date =$("#srfromDate").val();
		// var to_date = $("#srtoDate").val();
		var from_date = this.state.fromDate;
		var to_date = this.state.toDate;
            // var transporter_code = [];
            // if(localStorage.getItem("transportercode")!=undefined && localStorage.getItem("transportercode")!="undefined")
            // {
            //     var transporter_code = JSON.parse(localStorage.getItem("transportercode"))
            // }
            // console.log("reqfilter", truckNo, from_date, to_date)
            if(from_date != '' || to_date != '' || truckNo !='' || tpt_code !='')
            {	
                //     console.log(from_date, to_date)
                //     from_date = moment.parseZone(new Date(from_date)).utcOffset("+05:30")._d
                //     to_date = moment.parseZone(new Date(to_date)).utcOffset("+05:30")._d
                //     var datediff = (to_date - from_date)
                //     var diffDays = Math.floor(datediff / 86400000)
                //     console.log(diffDays,"diffDays")
                //     if(diffDays <= 5)
                //     {
                    if(from_date != undefined && from_date != "" && to_date != undefined && to_date != "")
                    {
                        from_date = moment.parseZone(new Date(from_date)).utcOffset("+05:30").format("YYYY-MM-DD")
                        to_date = moment.parseZone(new Date(to_date)).utcOffset("+05:30").format("YYYY-MM-DD")
                    }
                    var check = 0 ; 
                    if(from_date !=undefined && from_date !="")
                    {
                        if(to_date ==undefined || to_date == "")
                        {
                            check  = 1 ;
                        }
                    } 
                    if(to_date !=undefined && to_date !="")
                    {
                        if(from_date ==undefined || from_date == "")
                        {
                            check  = 2 ;
                        }
                    }
                    if(check == 0)
                    {
                        var reqparams = {		
                            // transporter_code:transporter_code,	
                            device_id : truckNo,
                            // tpt_code:tpt_code,
                            startdate : from_date,
                            enddate : to_date,
                        }
                    
                        // try{
                        //     if(localStorage.getItem("pc") != "" && localStorage.getItem("pc") != undefined)
                        //     {
                        //         reqparams.plant_code = localStorage.getItem("pc");
                        //     }
                        // }catch(e){
                            
                        // }
                        // console.log("reqparams ", reqparams)
                        this.setState({
                            loadshow:'show-m',
                            overly:'show-m',
                        })
                        redirectURL.post("/consignments/stoppagereportdata",reqparams)    
                        .then((response) => {
                            var records =[];
                            try{

                                if(response.data.report_data !== "" && response.data.report_data !== undefined)
                                {
                                    records = JSON.parse(response.data.report_data);
                                    records = records.filter(e => e.no_of_stoppages !== 0);
                                }
    
    
                            }
                            catch(e){

                            }
                            // console.log(records,"records")
                            this.setState({
                                loadshow:'show-n',
                                overly:'show-n',
                                rowData:records
                            })
                            
                        })
                        .catch(function(e){
                            console.log("Error ",e)
                        })
                    }    
                // }
                    else
                    {
                        this.setState({
                            show:true,
                            basicTitle:'From Date and To Date are required',
                            basicType:"danger",
                            loadshow:'show-n',
                        })
                    }
            }
        
            else
            {
                this.setState({
                    show:true,
                    basicTitle:'Please select atleast one input',
                    basicType:"danger",
                    loadshow:'show-n',
                })
            }
		
	}

	onChangeDepartment(truckNos){
		this.setState(
			{ truckNos },
			() => console.log(`truckNos Option selected:`, this.state.truckNos)
		);
	}

    handlerFromDateTime = (event, currentDate, selectedDate) => {
        
        var from_date = moment(new Date(event._d)).format('YYYY-MM-DD');
        this.setState({
            from_date
        });
    }
    handlerToDateTime = (event, currentDate, selectedDate) => {      
		var to_date = moment(new Date(event._d)).format('YYYY-MM-DD');

		this.setState({
            to_date
        });
    }

	initalList =(event)=>{
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truck_no_option;
        var tpttrucks = this.state.tpttrucks;
        var tptunqrecords = []
        if(tpttrucks.length > 0)
        {
            dataset.filter(f =>
				tptunqrecords.push(f)
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        tptunqrecords = tptunqrecords.slice(0,100);
        this.setState({
            filteredData : tptunqrecords,
            showTrucksList : "show-m",
        })
    }

	handlerForm = (event) => {    	
        $(".trucks-dropdown").removeClass("show-n");
        var dataset = this.state.truck_no_option;       
        var tptunqrecords = []
		console.log("inputval", $("#inputTruck").val())		
        if(dataset.length > 0)
        {
            dataset.filter(f =>
				tptunqrecords.push(f)                
            );
        }
        else
        {
            tptunqrecords = dataset
        }
        // eslint-disable-next-line array-callback-return
        var filteredResult = tptunqrecords.filter((e) =>{
            if(e != "" && e != null)
            {
                return e.toString().toLowerCase().includes($("#inputTruck").val().toLocaleLowerCase());
            }
            
        });
        // console.log("filteredResult ", filteredResult)
        filteredResult = filteredResult.slice(0,100);
        this.setState({
            filteredData : filteredResult,
            showTrucksList : "show-m",
        })
    }

    onShowTPTGPSMapRoute(rownode){
        var node = rownode.data;
        console.log("Node ", node)
        this.setState({
            loadshow:"show-m",
            overly:"show-m"
        })
        if (googleAnalytics.page.enableGA) {
            let eventOptions = {
				"category": this.state.pagetitle,
				"action": this.state.eventGridAction,
				"label": googleAnalytics.page.action.viewRoute,
			}
			googleAnalytics.logEvent(eventOptions);
		}
		const toDate = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
		var parameters = {
			truck_no:node.truck_no,
			from_date:moment.parseZone(node.trip_start_date).format('YYYY-MM-DD HH:mm:ss'),
			// to_date: node.reached_date ? moment.parseZone(node.reached_date).format('YYYY-MM-DD HH:mm:ss') : (node.expected_trip_end?node.expected_trip_end:toDate),
            to_date: node.status === 2 || node.status === 3 ? moment.parseZone().format('YYYY-MM-DD HH:mm:ss'): (node.reached_date ? moment.parseZone(node.reached_date).format('YYYY-MM-DD HH:mm:ss') : (node.expected_trip_end?node.expected_trip_end:toDate)),
            consignment_code: node.consignment_code,
			device_id:""
        }
        redirectURL.post("/consignments/showgpseodmap", parameters)
        .then((response) => {
            console.log("response ", response.data)
            this.setState({
                mapinfo:response.data,
                sliderRouteTranslate:"slider-translate-60p",
                loadshow:'show-n',
                overly:'show-m',
                truck_no:node.truck_no

        });
        })
    }
    onBtExport =() => {
        this.gridApi.exportDataAsExcel();
    };


    /**
	 * Developer Name: Siva G
	 * Bug NO: 3155
	 * Description: This function is called when FROM date is changed.
	 ***/
    handleFromDate = (event) => {
        let changedDate = moment(event._d).format("YYYY-MM-DD");
        if (this.state.toDate) {
          if (moment(changedDate).isAfter(moment(this.state.toDate))) {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "To Date should be greater than or equal to From Date",
              fromDate: moment(this.state.toDate)
                .subtract(1, "days")
                .format("YYYY-MM-DD"),
            });
          } else {
            this.setState({
              fromDate: changedDate,
            });
          }
        } else {
          this.setState({
            fromDate: changedDate,
          });
        }
    };
    /**
	 * Developer Name: Siva G
	 * Bug NO: 3155
	 * Description: This function is called when TO date is changed.
	 ***/
    handleToDate = (event) => {
    let changedDate = moment(event._d).format("YYYY-MM-DD");
    if (this.state.fromDate) {
        if (moment(changedDate).isBefore(moment(this.state.fromDate))) {
        this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "To Date should be greater than or equal to From Date",
            toDate: moment(this.state.fromDate)
            .add(1, "days")
            .format("YYYY-MM-DD"),
        });
        } else {
        this.setState({
            toDate: changedDate,
        });
        }
    } else {
        this.setState({
        toDate: changedDate,
        });
    }
    };

    render(){
        
		var columnWithDefs= [
			// {
			// 	headerName: "Serial No",
			// 	field: "numbers",
            //     width: 100,
            //     filter: true,
            //     resizable: true,
            //     sortable:true,
			// },
			{
                headerName:"",
                field:"_id",
                width:50,
                cellRendererSelector:function(params){
                    return {
                        component:"showtptgpsmap"
                    }
                }
            },

            {
				headerName: "Truck No",
				field: "truck_no",
                width: 130,
                filter: true,
                resizable: true,
                sortable:true,
               
			},
            {
                headerName: "Consignment No",
                field: "consignment_code",
                width: 150,
                editable: false,
                filter: "agSetColumnFilter",
                resizable: true
            },
            {
                headerName: "Status",
                field: "status",
                width: 140,
                
                // hide : hideStatus,
                filter: "agSetColumnFilter",
                resizable: true,
                valueGetter: (params) => {
                    //console.log("GPS ", params.data.gps_data_available);
                    if (params.data.status == 1) {
                        // return "Inside Plant"
                        return "Inside Coalmine"
                    }
                    else if (params.data.status == 2) {
                        return "In Transit"
                    }
                    // else if (params.data.dest_geofence_id != undefined && screen_type == "LOG-PRT") {
                    //     if (screen_url != "/prtconsignments") {
                    //         return "Reached Plant Gate";
                    //     }
                    // }
                    else if (params.data.status == 3) {
                        return "Near Plant"
                        // return "Near Destination"
                    }
                    else if (params.data.status == 4) {
                        return "Reached Plant"
                        // return "Reached Destination"
                    }

                    else if (params.data.status == 5) {
                        return "Left Plant"
                        // return "Left Destination"
                    }
                    else if (params.data.status == 6) {
                        return "Force Closed"
                    }
                    else if (params.data.status == 7) {
                        return "Proximity Trip Closure"
                    }
                    else if (params.data.status == 8) {
                        return "Unknown"
                    }
                    // else if (params.data.status == 8 && this.state.deptcode == "LOG-PRT") {
                    // 	return "POD Received From HERO" // this is MSIL Logic
                    // }
                    else {
                        return ""
                    }

                }
            },
            {
				headerName: "Source",
				field: "source",
                width: 130,
                filter: true,
                resizable: true,
                sortable:true,
               
			},

            {
				headerName: "Destination",
				field: "destination",
                width: 130,
                filter: true,
                resizable: true,
                sortable:true,
               
			},
            
            {
				headerName: "Stoppages",
				field: "no_of_stoppages",
                width: 110,
                filter: true,
                resizable: true,
                sortable:true,
			},

            {
				headerName: "Transporter Name",
				field: "transporter_name",
                width: 180,
                filter: true,
                resizable: true,
                sortable:true,
               
            },
            {
				headerName: "Stoppage 30-60 min",
				field: "stoppages_greater_30",
                width: 155,
                filter: true,
                resizable: true,
                sortable:true,
                // valueGetter:function(params)
                // {
                //     try{
                //         if(params.data.no_risk !== "" && params.data.no_risk !== undefined)
                //         {
                //             if(params.data.no_risk === 1)
                //             {
                //                 return "Low";
                //             }
                //         }
                //         else{
                //             return "";
                //         }
                //     }
                //     catch(e){}
                // }
               
			},
            {
				headerName: "Stoppage 60-90 min",
				field: "stoppages_greater_60",
                width: 155,
                filter: true,
                resizable: true,
                sortable:true,
                // valueGetter:function(params)
                // {
                //     try{
                //         if(params.data.medium_risk !== "" && params.data.medium_risk !== undefined)
                //         {
                //             if(params.data.medium_risk === 1)
                //             {
                //                 return "Medium";
                //             }
                //         }
                //         else{
                //             return "";
                //         }
                //     }
                //     catch(e){}
                // }
               
			},
            {
				headerName: "Stoppage greater than 90 min",
				field: "stoppages_greater_90",
                width: 155,
                filter: true,
                resizable: true,
                sortable:true,
                // valueGetter:function(params)
                // {
                //     try{
                //         if(params.data.high_risk !== "" && params.data.high_risk !== undefined)
                //         {
                //             if(params.data.high_risk === 1)
                //             {
                //                 return "High";
                //             }
                //         }
                //         else{
                //             return "";
                //         }
                //     }
                //     catch(e){}
                // }
               
			},

			{
				headerName: "Running Time (+/-)",
				field: "actual_time_taken",
                width: 200,
                filter: true,
                resizable: true,
                sortable:true
            },
          
            {
				headerName: "Risk Level",
				field: "risk_level",
                width: 120,
                filter: true,
                resizable: true,
                sortable:true,
               
            },
          
		
          
          
		];
		var listData = [] 
        // eslint-disable-next-line array-callback-return
        this.state.filteredData.map(function(e){
        listData.push(<li className="dropdown-truck-no">{e}</li>)
        })
        return (
            
    		<div className={(this.state.screenView == "Mobile")?"":"container-fluid"} style={(this.state.screenView == "Mobile")?{marginTop:"2em"}: {}}>
                <div className={"dataLoadpage " +(this.state.loadshow)}>
				</div>
				<div className={"dataLoadpageimg " +(this.state.loadshow)}>
					<div class="loader-box">
						<div class="loader-box">
							<div className={`new-loader ${this.state.loadshow}`}></div>
						</div>
					</div>
				</div>
				<div className={"overlay-part " + (this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
				<SweetAlert
	            show={this.state.show}
	            type={this.state.basicType}
	            title={this.state.basicTitle}
	            onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <div className=''>
					<div className="mb-4">
						<h5>
							<i className="icofont icofont-vehicle-delivery-van cus-i mr-1 mt-1"></i><span style={{ fontFamily: "Inter-SemiBold" }}>Stoppages Report</span>
						</h5>
					</div>
				</div>
                <div className="card">
                    <div className={(this.state.screenView == "Mobile")?"card-body":"card-body col-xl-12 col-lg-12"}>
						<div className={"trucks-dropdown "+this.state.showTrucksList} style={{top:"94px"}}>
							<ul>
								{listData}
							</ul>
						</div>
						<div className="row">
                            <div className='col-xl-9 col-lg-9'>
                                <div className='row'>
                                    <div className="col-xl-3 col-lg-3 form-group" style={{ minWidth: "200px"}}>
                                        <label>From Date</label>
                                        {
                                        /**
                                         * Developer Name: Siva G
                                         * Bug NO: 3155
                                         * Description: Changed to react-datetime.
                                         ***/
                                        }
                                        <Datetime
                                            timeFormat={false}
                                            value={this.state.fromDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: "From",
                                                name: "fromDate",
                                                autoComplete: "off",
                                            }}
                                            dateFormat="YYYY-MM-DD"
                                            name="fromDate"
                                            onChange={this.handleFromDate.bind(this)}
                                            required
                                        />
                                        {/* <input 
                                        type="text" name="date" id="srfromDate" autoComplete="off" className="datetimepicker_date form-control" readOnly="true"style={{ backgroundColor: 'white' }}/> */}
                                    </div>

                                    <div className="col-xl-3 col-lg-3 form-group" style={{ minWidth: "200px"}}>
                                        <label>To Date</label>
                                        {
                                        /**
                                         * Developer Name: Siva G
                                         * Bug NO: 3155
                                         * Description: Changed to react-datetime.
                                         ***/
                                        }
                                        <Datetime
                                            timeFormat={false}
                                            value={this.state.toDate}
                                            disableCloseOnClickOutside={false}
                                            closeOnSelect={true}
                                            inputProps={{
                                                placeholder: "To",
                                                name: "toDate",
                                                autoComplete: "off",
                                            }}
                                            dateFormat="YYYY-MM-DD"
                                            name="toDate"
                                            onChange={this.handleToDate.bind(this)}
                                            required
                                        />
                                                        {/* <input type="text" name="date" id="srtoDate" autoComplete="off" className="datetimepicker_date form-control" readOnly="true" style={{ backgroundColor: 'white' }}/> */}
                                    </div>
                                    <div className="col-xl-4 col-lg-4 form-group">
                                        <label></label>
                                        <button type="submit" onClick={this.onClickFilterSafetyReport.bind(this)} class="btn btn-success mt-30p">Get Data</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <button
                                    onClick={this.onBtExport.bind(this)}
                                    style={{ marginTop: '30px', fontWeight: 'bold' }}
                                    className="btn btn-warning float-right"
                                >
                                    Export to Excel
                                </button>
                            </div>
						</div>
                        {/* <p>Note* : Data can be fetched only for 5 days window</p> */}
                        <div id="myGrid" style={{width:"100%",height:"550px"}} className={"ag-theme-balham"}>    
                        <AgGridReact
                            modules={this.state.modules}
                            columnDefs={columnWithDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={this.state.rowData}
                            enableCharts={false}
                            // autoGroupColumnDef={this.state.autoGroupColumnDef}
                            onGridReady={this.onGridReady}
                            onGridState={this.onGridState}
                            frameworkComponents={this.state.frameworkComponents}
                            statusBar={this.state.statusBar}
                            sideBar={this.state.sideBar}
                            stopEditingWhenGridLosesFocus= {true}
                            paginationPageSize={1000}
                            pagination={true}
                            gridOptions={{
                                context: { componentParent: this },
                            }}
                            // components={this.state.components}
                            enableRangeSelection= {true}
                            // onCellClicked={this.onCellClickedInTransit.bind(this)}
                            // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                            />
                        </div>
                        {/* <p className="float-right" style = {{fontWeight:"bold"}}> Showing Rows {this.state.alrData+" of "+this.state.total_data} </p>
                        <div className="row col-xl-12"  style ={{marginTop:"2%"}}>
                            <div className="col-lg-6">
                                {this.state.page_no > 0 ?
                                <button type="submit" onClick={this.onClickPreviousData.bind(this)} className="btn btn-success">Previous</button>
                                :""}
                            </div>
                            <div className="col-lg-6">
                                {this.state.rowData.length >= 1000?
                                <button type="submit" onClick={this.onClickNextData.bind(this)} className="btn btn-success float-right">Next</button>
                                :""}
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* <div className={"dataLoadpage " +(this.state.loadshow)}>
                </div>
                <div className={"dataLoadpageimg " +(this.state.loadshow)}>
                    <div className="loader-box"><div className="loader-box"><div className="rotate dashed colored"></div></div></div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div> */}

				{this.state.sliderRouteTranslate != ''?
					 <div className={"slide-r "+(this.state.sliderRouteTranslate)} style={{overflow:"auto", marginTop:'40px'}}>
					 	<div className="slide-r-title">
							<h4>
								View Route
                                <span className="float-right closebtn" style={{marginRight:"25px"}} 
                                onClick={this.onClickHideAll.bind(this)} >X</span>
										
							</h4>
						</div>
						<div className="slide-r-body" style={{position:"relative"}}>
							<div className="row col-xl-12 col-lg-12 ml-0p n-p-0">
                                <DrawMap 
                                    context={this} 
                                    mapFor={"consignment"}
                                    mapinfo={this.state.mapinfo}
                                    stoppagesHide={true}  />
                                    <div className="col-xl-12 col-lg-12 n-p-0">
                                        <div className="crm-numbers pb-0">
                                            <h3 className="subH">Consignment Information</h3>
                                            <div className="col-xl-12 col-lg-12 row">
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Truck No</label>
                                                    <div>{this.state.truck_no}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Start Time</label>
                                                    <div>{(this.state.mapinfo.route_details.start_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.start_time) : "NA"}</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">End Time</label>
                                                    <div>{(this.state.mapinfo.route_details.start_time != '') ? getDDMMYYYYHHMMSS(this.state.mapinfo.route_details.end_time) : "NA"}</div>
                                                </div>
    
                                                <div className="col route-block">
                                                    <label className="sidebar-label">GPS Distance</label>
                                                    <div>{(this.state.mapinfo.route_details.distance_without_google) ? Math.round(this.state.mapinfo.route_details.distance_without_google / 1000) : "0"} Kms</div>
                                                </div>
                                                <div className="col route-block">
                                                    <label className="sidebar-label">Google Distance</label>
                                                    <div>{(this.state.mapinfo.route_details.distance) ? Math.round(this.state.mapinfo.route_details.distance / 1000) : "0"} Kms</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
							</div>
                        </div>
                    </div>
                :""}
				
            </div>
              
        );
    }
}


// eslint-disable-next-line no-unused-vars
function loadScript(url){
    var index  =window.document.getElementsByTagName("script")[0]
    var script = window.document.createElement("script")
    script.src=url
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

function dateComparator1(date1,date2){
	//console.log(date1,date2);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split("-")[1]);
	date1 = date1.replace(date1.split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split("-")[1]);
	date2 = date2.replace(date2.split("-")[1],date_2);
	var date1Number = monthToComparableNumber1(date1);
	var date2Number = monthToComparableNumber1(date2);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}	
}

function monthToComparableNumber1(date) {
	console.log(date.length);
	console.log(date);
	if (date === undefined || date === null || date.length !== 10) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}
function dateComparator(date1, date2) {
	// console.log("dateComparator");
	// console.log(date1.length);
	if(date1 != "" && date2 != "" && date1 != undefined && date2 != undefined ){
		// console.log(date1,date2);
	var date_1 = getMonthNumber(date1.split(" ")[0].split("-")[1]);
	date1 = date1.replace(date1.split(" ")[0].split("-")[1],date_1);
	var date_2 = getMonthNumber(date2.split(" ")[0].split("-")[1]);
	date2 = date2.replace(date2.split(" ")[0].split("-")[1],date_2);
	// console.log(date1,date2);
	var date1Number = monthToComparableNumber(date1);
	var date2Number = monthToComparableNumber(date2);
	// console.log(date1Number,date2Number);
	if (date1Number === null && date2Number === null) {
	  return 0;
	}
	if (date1Number === null) {
	  return -1;
	}
	if (date2Number === null) {
	  return 1;
	}
	return date1Number - date2Number;
	}
  }
function monthToComparableNumber(date) {
	// console.log(date,date.length);
	if (date === undefined || date === null || date.length !== 16) {
		return null;
	}
	var yearNumber = date.substring(6, 10);
	var monthNumber = date.substring(3, 5);
	var dayNumber = date.substring(0, 2);
	console.log(yearNumber,monthNumber,dayNumber);
	var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
	return result;
}

function getMonthNumber(monthName){
	var months = [
		'Jan', 'Feb', 'Mar', 'Apr', 'May',
		'Jun', 'Jul', 'Aug', 'Sep',
		'Oct', 'Nov', 'Dec'
		];
	var monthNum = months.indexOf(monthName)+1;
	if(monthNum < 10){
		monthNum = "0"+monthNum.toString();
	}
	return monthNum;
}


function loadDateTimeScript(){
	// alert("timeDate");
	$('.datetimepicker_mask').datetimepicker({
		format:'d-m-Y H:i'
	});
	$('.datetimepicker_date').datetimepicker({
		format:'Y-m-d',
		timepicker:false
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
	//script.src="http://xdsoft.net/jqplugins/datetimepicker/"
	script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}

$(document).on("change", "#srtoDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#srfromDate").val();
    var cargoDate=$("#srtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]
    console.log()
    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#srtoDate").val("endTime");
        alert("To Date should be greater than or equal to From Date");
    }
})

$(document).on("change", "#srfromDate", function(e) {
    e.preventDefault();
    var bookingDate=$("#srfromDate").val();
    var cargoDate=$("#srtoDate").val();
    // var bookingDate = bookingDate.split("-")[2]+"-"+bookingDate.split("-")[1]+"-"+bookingDate.split("-")[0]
    // var cargoDate = cargoDate.split("-")[2]+"-"+cargoDate.split("-")[1]+"-"+cargoDate.split("-")[0]

    var startDate = new Date(bookingDate);
    var endDate = new Date(cargoDate);
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    console.log(startTime,endTime);
    if(startTime > endTime)
    {
        $("#srfromDate").val("startTime");
        alert("From Date should be less than equal to To Date");
    }
})

function DateEditor () {}

// gets called once before the renderer is used
DateEditor.prototype.init = function(params) {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.value = params.value;

    // https://jqueryui.com/datepicker/
    $(this.eInput).datetimepicker({
        dateFormat: "yy-mm-dd hh:mm",
        changeMonth: true,
        changeYear: true,
        timepicker: true,
    });
};

// gets called once when grid ready to insert the element
DateEditor.prototype.getGui = function() {
    return this.eInput;
};

// focus and select can be done after the gui is attached
DateEditor.prototype.afterGuiAttached = function() {
    this.eInput.focus();
    this.eInput.select();
};

// returns the new value after editing
DateEditor.prototype.getValue = function() {
	// console.log(this.eInput.value);
    return this.eInput.value;
};

// any cleanup we need to be done here
DateEditor.prototype.destroy = function() {
    // but this example is simple, no cleanup, we could
    // even leave this method out as it's optional
};

// if true, then this editor will appear in a popup
DateEditor.prototype.isPopup = function() {
    // and we could leave this method out also, false is the default
    return false;
};

function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};


function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });
    return map;
}
function secondsToHM(seconds) {
    seconds = Number(seconds);
    // var d = Math.floor(seconds / (3600*24));
    // var h = Math.floor(seconds % (3600*24) / 3600);
    // var m = Math.floor(seconds % 3600 / 60);
    // var s = Math.floor(seconds % 60);
    
    // var dDisplay = d >= 0 ? d + (d == 1 ? " Day " : " Days ") : "";
    // var hDisplay = h >= 0 ? h + (h == 1 ? " Hr " : " Hrs ") : "";
    // var mDisplay = m >= 0 ? m + (m == 1 ? " Mins " : " Mins ") : "";
    // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    // return dDisplay + hDisplay + mDisplay;
    
    var hh = Math.floor((seconds / 3600));
    var hrr = Math.floor(seconds - (hh*3600));
    var mm = Math.floor(hrr/60)
    if(mm < 10)
    {
        var mins = "0"+mm;
    }
    else
    {
        var mins = mm;
    }

    if(hh < 10)
    {
        var hrs = "0"+hh;
    }
    else
    {
        var hrs = hh;
    }
    return hrs+":"+mins;
}

function isEmpty(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key))
			return false;
	}
	return true;
}

function dateDifferenceInHHMM(date1, date2) {
    if(date2 !== 0)
    {
        const diff = moment.utc(moment(date2).diff(moment(date1)));
        return diff.format("HH:mm");
    }
    else{
        return "";
    }
}